import React, { useEffect, useState } from "react";
import {
    Container,
    Dimmer,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Input,
    Image,
    Loader,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Icon,
    Button,
    Message,
} from "semantic-ui-react";
import OrderStatusSteps from "../../components/OrderStatusSteps";
import { useSearchParams } from "react-router-dom";
import {
    getOrderByInvoice,
} from "../../services/requests.service";
import Order from "../../types/entities/Order";
import { formatDateTime } from "../../utils/datetimeUtils";
import logo from "../../assets/img/logonegro.svg";

const OrdersDetail = () => {
    const [order, setOrder] = useState<Order|null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [invoice, setInvoice] = useState("");
    const [searchInput, setSearchInput] = useState("");

    // get the order id from params
    const [searchParams, setSearchParams] = useSearchParams();

    const fetchOrder = async () => {
        if (!invoice) return;
        try {
            setLoading(true);
            const response = await getOrderByInvoice(invoice);

            if (response?.status === 200) {
                const orderResponse: Order = response?.data;
                setOrder(orderResponse);
                setError("");
            } else {
                setOrder(null);
                throw new Error("El pedido no fue encontrado, favor de verificar el folio.");
            }
        } catch (error: any) {
            setError(error?.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchInput = () => {
        // validate lenght and set searchParam
        if (searchInput.length === 0) {
            setError("Por favor ingresa un numero de folio");
            setOrder(null);
        } else {
            setSearchParams({ invoice: searchInput });
        }
    };

    useEffect(() => {
        if (searchParams.get("invoice") !== null) {
            console.log("invoice found");
            setSearchInput(searchParams.get("invoice")!);
            setInvoice(searchParams.get("invoice")!);
        } else {
            console.log("no invoice found");
        }
    }, [searchParams]);

    useEffect(() => {
        fetchOrder();
        // eslint-disable-next-line
    }, [invoice]);

    return (
        <>
            <Dimmer active={loading} inverted>
                <Loader size="large">Cargando</Loader>
            </Dimmer>

            <Container>
                <Grid>
                    <GridRow style={{ marginTop: "5em", marginBottom: "2em" }}>
                        <GridColumn textAlign="center">
                            <Image src={logo} size="medium" centered />
                            <Header as="h1" textAlign="center">
                                Busca tu pedido
                            </Header>
                            <Input fluid search
                                size="big" 
                                value={searchInput} 
                                placeholder='Numero de folio #2024...' 
                                onChange={(e) => setSearchInput(e.target.value)}
                                action={ <Button primary onClick={() => handleSearchInput()}>Buscar</Button> } />

                            {error && (
                                <Message
                                    closable
                                    error
                                    header="Se ha producido un error."
                                    content={error}
                                />
                            )}
                        </GridColumn>
                    </GridRow>

                    {order !== null && (
                        <>
                        <GridRow>
                            <GridColumn>
                                <Header as="h1" floated="left">
                                    Detalle del pedido #{order?.invoice}
                                </Header>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                                <OrderStatusSteps currentStep={order?.status} />
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                                <Table basic='very' fixed celled>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell textAlign="right">Movimiento</TableHeaderCell>
                                            <TableHeaderCell>Fecha y hora</TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>

                                    <TableBody>
                                        <TableRow>
                                            <TableCell textAlign="right"><Icon circular inverted name="cart" /><b>Creación del pedido</b></TableCell>
                                            <TableCell>{ formatDateTime(order?.orderDate) }</TableCell>
                                        </TableRow>
                                        { order?.productionStartDate && (<TableRow>
                                            <TableCell textAlign="right"><Icon circular inverted name="phone" /><b>Inicio de producción</b></TableCell>
                                            <TableCell>{ formatDateTime(order?.productionStartDate) }</TableCell>
                                        </TableRow>) }
                                        { order?.productionEndDate && (<TableRow>
                                            <TableCell textAlign="right"><Icon circular inverted name="truck" /><b>Fin de producción</b></TableCell>
                                            <TableCell>{ formatDateTime(order?.productionEndDate) }</TableCell>
                                        </TableRow>) }
                                        { order?.installationDate && (<TableRow>
                                            <TableCell textAlign="right"><Icon circular inverted name="calendar" /><b>Fecha de instalación</b></TableCell>
                                            <TableCell>{ formatDateTime(new Date(order?.installationDate + " " + order?.installationTime)) } </TableCell>
                                        </TableRow>) }
                                    </TableBody>
                                </Table>
                            </GridColumn>
                        </GridRow>
                        </>
                    )}
                </Grid>

            </Container>
        </>
    );
};

export default OrdersDetail;
