import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const PrivateRoute = () => {
    const auth = useAuth();
    if (!auth.accessToken) return <Navigate to="login" replace />
    return <Outlet />
};

export default PrivateRoute;
