import React from 'react';
import { DT_CUSTOM_STYLES, DT_PAGINATION_COMPONENT_OPTIONS } from '../utils/constants';
import { Container, Loader, Message } from 'semantic-ui-react';
import DataTable from 'react-data-table-component';
import LuxonDataTableProps from '../types/props/LuxonDataTableProps';

const LuxonDataTable = (props: LuxonDataTableProps) => {

    const noDataComponent = (
        <Container>
            <Message icon='info' info header="Información de la sección." 
                content="Aun no se ha creado ningun registro." />
        </Container>
    );

    const loaderComponent = (
        <Loader active inline style={{margin: "10px"}}>Cargando...</Loader>    
    );

    return (
        <DataTable
            columns={props.columns}
            data={props.data}
            customStyles={DT_CUSTOM_STYLES}
            noDataComponent={noDataComponent}
            striped
            pagination
            paginationServer
            progressPending={props.loading}
            progressComponent={loaderComponent}
            paginationComponentOptions={DT_PAGINATION_COMPONENT_OPTIONS}
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.handlePerRowsChange}
            onChangePage={props.handlePageChange} 
        />
    );
};

export default LuxonDataTable;