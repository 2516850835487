import React from "react";
import { useAuth } from "../hooks/AuthProvider";
import { Route, Routes } from "react-router-dom";
import RequestsList from "../pages/orders/RequestsList";
import OrdersCreate from "../pages/orders/OrdersCreate";
import OrdersList from "../pages/orders/OrdersList";
import OrdersDetail from "../pages/orders/OrdersDetail";
import RemindersList from "../pages/orders/OrderRemindersList";
import ProductsList from "../pages/products/ProductsList";
import ProductsCreate from "../pages/products/ProductsCreate";
import AdvisorsList from "../pages/advisors/AdvisorsList";
import AdvisorsCreate from "../pages/advisors/AdvisorsCreate";
import Login from "../pages/auth/Login";
import OrderTracking from "../pages/orders/OrderTracking";

interface Props {
    element: React.ReactNode;
}

const LuxonRoutes = ({ element }: Props) => {
    const auth = useAuth();
    return (
        <>
            <Routes>
                { !auth.accessToken && <Route path="/" element={<Login />} /> }
                <Route path="/login" element={<Login />} />
                <Route path="/orders-search" element={<OrderTracking />} />
                <Route element={element}>
                    { auth.userData?.role === "administrator" && (
                        <>
                            <Route path="/" element={<RequestsList />} />
                            <Route path="/requests" element={<RequestsList />} />
                            <Route path="/requests/create" element={<OrdersCreate />} />
                            <Route path="/orders" element={<OrdersList />} />
                            <Route path="/orders/:orderId/details" element={<OrdersDetail />} />
                            <Route path="/reminders" element={<RemindersList />} />
                            <Route path="/products" element={<ProductsList />} />
                            <Route path="/products/create" element={<ProductsCreate />} />
                            <Route path="/advisors" element={<AdvisorsList />} />
                            <Route path="/advisors/create" element={<AdvisorsCreate />} />
                        </>
                    ) }

                    { auth.userData?.role === "orders" && (
                        <>
                            <Route path="/" element={<RequestsList />} />
                            <Route path="/requests" element={<RequestsList />} />
                            <Route path="/requests/create" element={<OrdersCreate />} />
                            <Route path="/orders" element={<OrdersList />} />
                            <Route path="/orders/:orderId/details" element={<OrdersDetail />} />
                        </>
                    ) }

                    { auth.userData?.role === "installations" && (
                        <>
                            <Route path="/" element={<OrdersList />} />
                            <Route path="/orders" element={<OrdersList />} />
                            <Route path="/orders/:orderId/details" element={<OrdersDetail />} />
                            <Route path="/reminders" element={<RemindersList />} />
                        </>
                    ) }
                </Route>
            </Routes>
        </>
    );
};

export default LuxonRoutes;
