import { format } from "date-fns";
import { es } from "date-fns/locale";

export const formatDateTime = (date: Date | undefined): string => {
    return date ? format(date, "dd 'de' MMMM yyyy 'a las' hh:mm aaa", { locale: es }) : "";
};

export const differenceInBusinessHours = (date: Date) => {
    const realDate = new Date(date);
    const now = new Date();
    const diff = Math.abs(now.getTime() - realDate.getTime());
    const realDiff = Math.ceil(diff / (1000 * 3600));
    return realDiff;
};

export const daysPassedSince = (date: Date) => {
    const realDate = new Date(date);
    const now = new Date();
    const diff = Math.abs(now.getTime() - realDate.getTime());
    const realDiff = Math.floor(diff / (1000 * 3600 * 24));
    return realDiff;
};
