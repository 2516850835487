import React, { useEffect, useState } from "react";
import { Step } from "semantic-ui-react";
import OrderStatusStepsProps from "../types/props/OrderStatusStepsProps";
import StepItem from "../types/models/StepItem";

const defaultSteps: StepItem[] = [
    {
		link: true,
        key: "VALIDANDO_PEDIDO",
		order: 1,
        icon: "upload",
        title: "Validando pedido",
		active: false,
		disabled: false,
		completed: false,
    },
    {
        link: true,
        key: "EN_PRODUCCION",
		order: 2,
        icon: "wrench",
        title: "En producción",
		active: false,
		disabled: false,
		completed: false,
    },
    {
        link: true,
        key: "LISTO_PARA_AGENDAR",
		order: 3,
        icon: "phone",
        title: "Listo para agendar",
		active: false,
		disabled: false,
		completed: false,
    },
    {
        link: true,
        key: "AGENDADO",
		order: 4,
        icon: "checked calendar",
        title: "Agendado",
		active: false,
		disabled: false,
		completed: false,
    },
    {
        link: true,
        key: "PROYECTO_TERMINADO",
		order: 5,
        icon: "flag checkered",
        title: "Proyecto terminado",
		active: false,
		disabled: false,
		completed: false,
    },
];

const OrderStatusSteps = (props: OrderStatusStepsProps) => {
	// set a big number to be overwrote until status is found
	const [steps, setSteps] = useState<StepItem[]>([]);

	useEffect(() => {
		const fillStatuses = () => {
			let stepIndex: number = 10;
			let mappedSteps: StepItem[] = [];

			if (props.currentStep === "CANCELADO") {
				const canceledStep: StepItem = {
					link: true,
					key: "CANCELADO",
					order: 0,
					icon: "remove circle",
					title: "Cancelado",
					active: false,
					disabled: false,
					completed: false,
				};
				mappedSteps.push(canceledStep);
			} else {
				mappedSteps = defaultSteps.map(step => {
					const newStep = { ...step };
					if (newStep.key === props.currentStep) {
						stepIndex = newStep.order;
						newStep.active = true;
						newStep.disabled = false;
					} else {
						if (stepIndex > newStep.order) {
							newStep.completed = true;
						}
					}
					return newStep;
				});
			}
			setSteps(mappedSteps);
        };
        fillStatuses();
	}, [ props ]);

    return (
		<>
			<Step.Group
				items={steps}
				widths={5}
				size="tiny"
			/>
		</>
    );
};

export default OrderStatusSteps;
