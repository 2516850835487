import React, { useEffect, useState } from "react";
import { Button, Card, Container, Header, Icon, Label } from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { getReminders } from "../../services/requests.service";
import Order from "../../types/entities/Order";
import { ORDERS_STATUSES } from "../../utils/constants";
import LuxonDataTable from "../../components/LuxonDataTable";
import { daysPassedSince, formatDateTime } from "../../utils/datetimeUtils";

const OrderRemindersList = () => {
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

	const columns = [
        {
            name: "Folio",
            selector: (row: Order) => `#${row.invoice}`,
        },
        {
            name: "Fecha de compra",
            selector: (row: Order) => formatDateTime(row.orderDate),
        },
        {
            name: "Cliente",
            selector: (row: Order) => `${row.name} ${row.lastName}`,
        },
        {
            name: "Dias transcurridos",
            selector: (row: Order) => daysPassedSince(row.orderDate),
        },
        {
            name: "Estatus",
            cell: (row: Order) => (
                <>
                    <Label color={ORDERS_STATUSES[row.status].color}>{ORDERS_STATUSES[row.status].name}</Label>
                </>
            ),
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: Order) => (
                <>
                    <Button secondary icon size="mini" href={`orders/${row.id}/details`}>
                        <Icon name="search" />
                    </Button>
                </>
            ),
        },
    ];

	const fetchData = async (page: number) => {
        setLoading(true);
        try {
			const response = await getReminders(perPage, page-1, "createdAt,asc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getReminders(newPerPage, page-1, "createdAt,asc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1);
        // eslint-disable-next-line
    }, []);

	return (
		<>
			<Navbar />
			<Container className="container-custom">
				<Header as="h1">Recordatorio anticipado</Header>
				<Card fluid>
                    <LuxonDataTable 
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>
			</Container>
		</>
	);
};

export default OrderRemindersList;
