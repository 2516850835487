import axios from "axios";
import { API_ORDERS_REQUESTS_URL, API_ORDERS_ORDERS_URL, API_ORDERS_REMINDERS_URL, API_ORDERS_URL, ORDER_STATUS_LISTO_PARA_AGENDAR, API_ORDERS_BY_INVOICE_URL } from "../utils/constants";

export const createRequest = async (payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(API_ORDERS_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateRequestStatus = async (
    orderId: string | undefined,
    currentStatus: string,
    installationDate?: string,
    installationTime?: string
) => {
    const token = localStorage.getItem("access_token");
    try {
        let extraRequestParams = "";
        if (currentStatus === ORDER_STATUS_LISTO_PARA_AGENDAR) {
            extraRequestParams = `&installation_date=${installationDate}&installation_time=${installationTime}`;
        }
        const response = await axios.put(`${API_ORDERS_URL}/${orderId}?current_status=${currentStatus}${extraRequestParams}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const cancelOrderById = async (
    orderId: string | undefined
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_ORDERS_URL}/${orderId}/cancel`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getOrderById = async (
    orderId: string | undefined
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_ORDERS_URL}/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getOrderByInvoice = async (
    invoice: string | null
) => {
    try {
        const response = await axios.get(`${API_ORDERS_BY_INVOICE_URL}/${invoice}`, {
            headers: {},
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getRequests = async (
    size: number = 10,
    page: number = 0,
    sort: string = "name,asc"
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_ORDERS_REQUESTS_URL}?page=${page}&size=${size}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getOrders = async (
    size: number = 10,
    page: number = 0,
    sort: string = "name,asc"
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_ORDERS_ORDERS_URL}?page=${page}&size=${size}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getReminders = async (
    size: number = 10,
    page: number = 0,
    sort: string = "name,asc"
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_ORDERS_REMINDERS_URL}?page=${page}&size=${size}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updatePaymentProof = async (
    orderId: string | undefined,
    formData: FormData
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_ORDERS_URL}/${orderId}/uploadFiles`, formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
