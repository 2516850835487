import OrderStatus from "../types/models/OrderStatus";

// env constants
// export const API_URL = "http://localhost:8080/";
export const API_URL = process.env.REACT_APP_API_URL;

// utils constants
export const LOCAL_TOKEN = "access_token";
export const LOCAL_USER = "user";

// url constants (endpoints)
export const API_AUTH_URL = API_URL + "auth";
export const API_AUTH_LOGIN_URL = API_AUTH_URL + "/login";
export const API_ORDERS_URL = API_URL + "orders";
export const API_ORDERS_BY_INVOICE_URL = API_ORDERS_URL + "/invoice";
export const API_ORDERS_REQUESTS_URL = API_ORDERS_URL + "/requests";
export const API_ORDERS_ORDERS_URL = API_ORDERS_URL + "/orders";
export const API_ORDERS_REMINDERS_URL = API_ORDERS_URL + "/reminders";
export const API_ADVISORS_URL = API_URL + "advisors";
export const API_PRODUCTS_URL = API_URL + "products";

// utils
export const ORDER_STATUS_VALIDANDO_PEDIDO = "VALIDANDO_PEDIDO";
export const ORDER_STATUS_EN_PRODUCCION = "EN_PRODUCCION";
export const ORDER_STATUS_LISTO_PARA_AGENDAR = "LISTO_PARA_AGENDAR";
export const ORDER_STATUS_AGENDADO = "AGENDADO";
export const ORDER_STATUS_PROYECTO_TERMINADO = "PROYECTO_TERMINADO";
export const ORDER_STATUS_CANCELADO = "CANCELADO";


export const ORDERS_STATUSES: OrderStatus = {
    VALIDANDO_PEDIDO: {
		name: "Validando pedido",
		color: "yellow"
	},
	EN_PRODUCCION: {
		name: "En produccion",
		color: "grey"
	},
	LISTO_PARA_AGENDAR: {
		name: "Listo para agendar instalacion",
		color: "green"
	},
	AGENDADO: {
		name: "Agendado",
		color: "orange"
	},
	PROYECTO_TERMINADO: {
		name: "Proyecto terminado",
		color: "blue"
	},
	CANCELADO: {
		name: "Cancelado",
		color: "red"
	},
};

// datatables
export const DT_CUSTOM_STYLES = {
    head: {
        style: {
            fontSize: "14px",
            fontWeight: 800,
        },
    },
};

export const DT_PAGINATION_COMPONENT_OPTIONS = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
};

// input options
export const OPTIONS_NUEVO_LEON_CITIES = [
    { key: "001", value: "001", text: "Abasolo" },
    { key: "002", value: "002", text: "Agualeguas" },
    { key: "003", value: "003", text: "Los Aldamas" },
    { key: "004", value: "004", text: "Allende" },
    { key: "005", value: "005", text: "Anáhuac" },
    { key: "006", value: "006", text: "Apodaca" },
    { key: "007", value: "007", text: "Aramberri" },
    { key: "008", value: "008", text: "Bustamante" },
    { key: "009", value: "009", text: "Cadereyta Jiménez" },
    { key: "010", value: "010", text: "El Carmen" },
    { key: "011", value: "011", text: "Cerralvo" },
    { key: "012", value: "012", text: "Ciénega de Flores" },
    { key: "013", value: "013", text: "China" },
    { key: "014", value: "014", text: "Doctor Arroyo" },
    { key: "015", value: "015", text: "Doctor Coss" },
    { key: "016", value: "016", text: "Doctor González" },
    { key: "017", value: "017", text: "Galeana" },
    { key: "018", value: "018", text: "García" },
    { key: "019", value: "019", text: "San Pedro Garza García" },
    { key: "020", value: "020", text: "General Bravo" },
    { key: "021", value: "021", text: "General Escobedo" },
    { key: "022", value: "022", text: "General Terán" },
    { key: "023", value: "023", text: "General Treviño" },
    { key: "024", value: "024", text: "General Zaragoza" },
    { key: "025", value: "025", text: "General Zuazua" },
    { key: "026", value: "026", text: "Guadalupe" },
    { key: "027", value: "027", text: "Los Herreras" },
    { key: "028", value: "028", text: "Higueras" },
    { key: "029", value: "029", text: "Hualahuises" },
    { key: "030", value: "030", text: "Iturbide" },
    { key: "031", value: "031", text: "Juárez" },
    { key: "032", value: "032", text: "Lampazos de Naranjo" },
    { key: "033", value: "033", text: "Linares" },
    { key: "034", value: "034", text: "Marín" },
    { key: "035", value: "035", text: "Melchor Ocampo" },
    { key: "036", value: "036", text: "Mier y Noriega" },
    { key: "037", value: "037", text: "Mina" },
    { key: "038", value: "038", text: "Montemorelos" },
    { key: "039", value: "039", text: "Monterrey" },
    { key: "040", value: "040", text: "Parás" },
    { key: "041", value: "041", text: "Pesquería" },
    { key: "042", value: "042", text: "Los Ramones" },
    { key: "043", value: "043", text: "Rayones" },
    { key: "044", value: "044", text: "Sabinas Hidalgo" },
    { key: "045", value: "045", text: "Salinas Victoria" },
    { key: "046", value: "046", text: "San Nicolás de los Garza" },
    { key: "047", value: "047", text: "Hidalgo" },
    { key: "048", value: "048", text: "Santa Catarina" },
    { key: "049", value: "049", text: "Santiago" },
    { key: "050", value: "050", text: "Vallecillo" },
    { key: "051", value: "051", text: "Villaldama" },
];
