import React, { useState } from "react";
import {
    Button,
    Form,
    Grid,
    GridColumn,
    Header,
    Segment,
    Image,
    Message,
} from "semantic-ui-react";
import logo from "../../assets/img/logonegro.svg";
import { useAuth } from "../../hooks/AuthProvider";

const Login = () => {

    const auth = useAuth();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleLogin = async (e: any) => {
        e.preventDefault();

        try {
            await auth.login(username, password);
        } catch (error: any) {
            setError(error.message);
        }
    };

    return (
        <>
            <Grid
                textAlign="center"
                style={{ height: "90vh" }}
                verticalAlign="middle"
            >
                <GridColumn style={{ maxWidth: 450 }}>
                    <Image src={logo} size="medium" centered />
                    <Form error size="large" onSubmit={handleLogin}>
                        <Segment style={{ marginTop: 30 }}>
                            <Header as="h2" textAlign="center">
                                Iniciar sesion
                            </Header>

                            {error && (
                                <Message
                                    error
                                    header={false}
                                    content={error}
                                />
                            ) }

                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Usuario"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />

                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Contraseña"
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <Button primary fluid size="large" type="submit">
                                Entrar
                            </Button>
                        </Segment>
                    </Form>
                </GridColumn>
            </Grid>
        </>
    );
};

export default Login;
