import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './hooks/AuthProvider';
import LuxonRoutes from './routes/LuxonRoutes';
import PrivateRoute from './routes/PrivateRoute';

const App = () => {
  return (
    <BrowserRouter>
        <AuthProvider>
            <LuxonRoutes element={<PrivateRoute />} />
        </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
