import React, { useState } from "react";
import {
	Button,
	Container,
	Divider,
	Form,
	FormField,
	FormGroup,
	Grid,
	GridColumn,
	Header,
	Input,
	Message,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { createAdvisor } from "../../services/advisors.service";

const AdvisorsCreate = () => {

	const [userData, setUserData] = useState({
		name: "",
		lastName: "",
		phone: "",
		email: ""
	});
	const [error, setError] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChange = (e: any, { name, value }: any) => {
		setUserData({
			...userData,
			[name]: value,
		});
	};

	const handleChangePhone = (e: any, { name, value }: any) => {
        if (value.length <= 10) {
            setUserData({
                ...userData,
                [name]: value,
            });
        }
    };

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			if (userData.phone.length !== 10) {
                throw new Error("El telefono no es valido, favor de verificar que sean 10 digitos sin espacios.");
            }

			const response = await createAdvisor(userData);

			if (response?.status === 201) {
				window.location.href = "/advisors";
			} else {
				throw new Error("Hubo un error al crear el asesor.");
			}

		} catch (error: any) {
			setError(error.message);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Navbar />
			<Container style={{ marginTop: "7em" }}>
				<Form 
					error 
					onSubmit={handleSubmit}
					onReset={() => window.location.href = "/advisors"} >
					<Grid>
						<GridColumn floated="left" width={5}>
							<Header as="h1">Nuevo asesor</Header>
						</GridColumn>
						<GridColumn floated="right" width={5}>
							<Button floated="right" positive type="submit" disabled={isSubmitting}>Guardar</Button>
							<Button floated="right" type="reset">Cancelar</Button>
						</GridColumn>
					</Grid>

					<Divider />

					{error && (
                        <Message
                            error
                            header="Se ha producido un error."
                            content={error}
                        />
                    )}
				
					<FormGroup widths="equal">
						<FormField
							control={Input}
							label="Nombre"
							placeholder="Nombre(s)"
							name="name"
							value={userData.name}
							onChange={handleChange}
							required
						/>
						<FormField
							control={Input}
							label="Apellidos"
							placeholder="Apellidos"
							name="lastName"
							value={userData.lastName}
							onChange={handleChange}
							required
						/>
					</FormGroup>

					<FormGroup widths="equal">
						<FormField
							control={Input}
							type="number"
							label="Telefono"
							placeholder="ej. 8341490185"
							name="phone"
							value={userData.phone}
							onChange={handleChangePhone}
							required
						/>
						<FormField
							control={Input}
							label="Email"
							placeholder="ej. example@gmail.com"
							name="email"
							value={userData.email}
							onChange={handleChange}
						/>
					</FormGroup>

				</Form>
			</Container>
		</>
	);
};

export default AdvisorsCreate;
