import React from "react";
import { Container, Menu, Image } from "semantic-ui-react";
import logo from "../assets/img/logoblanco.svg";
import { useAuth } from "../hooks/AuthProvider";

const Navbar = () => {
    const auth = useAuth();

    const handleLogout = () => {
        auth.logout();
    }

    return (
        <>
            <Menu fixed="top" size="large" inverted>
                <Container>

                    <Menu.Item as="a" href="/" header>
                        <Image size="tiny" src={logo} style={{ marginRight: "1.5em" }} />
                    </Menu.Item>

                    { auth.userData?.role === "orders" && (
                        <>
                            <Menu.Item as="a" href="/requests">Pedidos</Menu.Item>
                            <Menu.Item as="a" href="/orders">Ordenes</Menu.Item>
                        </>
                    ) }

                    { auth.userData?.role === "installations" && (
                        <>
                            <Menu.Item as="a" href="/orders">Ordenes</Menu.Item>
                            <Menu.Item as="a" href="/reminders">Recordatorio anticipado</Menu.Item>
                        </>
                    ) }

                    { auth.userData?.role === "administrator" && (
                        <>
                            <Menu.Item as="a" href="/requests">Pedidos</Menu.Item>
                            <Menu.Item as="a" href="/orders">Ordenes</Menu.Item>
                            <Menu.Item as="a" href="/reminders">Recordatorio anticipado</Menu.Item>
                            <Menu.Item as="a" href="/products">Productos</Menu.Item>
                            <Menu.Item as="a" href="/advisors">Asesores</Menu.Item>
                        </>
                    ) }

                    <Menu.Item onClick={ handleLogout } position="right">
                        Cerrar sesión
                    </Menu.Item>
                </Container>
            </Menu>
        </>
    );
};

export default Navbar;
