import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Container,
    Grid,
    GridColumn,
    Header,
    Icon,
    Label,
    Popup,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { getRequests } from "../../services/requests.service";
import { ORDERS_STATUSES } from "../../utils/constants";
import Order from "../../types/entities/Order";
import LuxonDataTable from "../../components/LuxonDataTable";
import { daysPassedSince, formatDateTime, differenceInBusinessHours } from "../../utils/datetimeUtils";

const RequestsList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            name: "Folio",
            selector: (row: Order) => <Label horizontal>{`#${row.invoice}`}</Label>,
        },
        {
            name: "Fecha de compra",
            selector: (row: Order) => formatDateTime(row.orderDate),
        },
        {
            name: "Cliente",
            selector: (row: Order) => `${row.name} ${row.lastName}`,
        },
        {
            name: "Dias transcurridos",
            selector: (row: Order) => daysPassedSince(row.orderDate),
        },
        {
            name: "Producto",
            selector: (row: Order) => row.product.name,
        },
        {
            name: "Asesor",
            selector: (row: Order) => `${row.advisor.name} ${row.advisor.lastName}`,
        },
        {
            name: "Estatus",
            cell: (row: Order) => (
                <>
                    <Label color={ORDERS_STATUSES[row.status].color}>{ORDERS_STATUSES[row.status].name}</Label>
                </>
            ),
        },
        {
            name: "Acciones",
            button: true,
            style: { justifyContent: "right", paddingRight: "16px", paddingLeft: "16px" },
            cell: (row: Order) => (
                <>
                    { differenceInBusinessHours(row.orderDate) > 24  && (
                        <Popup content='Pedido con mas de 24 horas en "Validando pedido"' 
                        trigger={<Icon link name="warning sign" color="red" size='large' />} />
                    )}
                    <Button secondary icon size="mini" href={`orders/${row.id}/details`}>
                        <Icon name="search" />
                    </Button>
                </>
            )
        },
    ];

	const fetchData = async (page: number) => {
        setLoading(true);
        try {
			const response = await getRequests(perPage, page-1, "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getRequests(newPerPage, page-1, "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <Container className="container-custom">
                <Grid>
                    <GridColumn floated="left" width={5}>
                        <Header as="h1">Pedidos</Header>
                    </GridColumn>
                    <GridColumn floated="right" width={5}>
                        <Button href="/requests/create" floated="right" primary>
                            Nuevo pedido
                        </Button>
                    </GridColumn>
                </Grid>

				<Card fluid>
                    <LuxonDataTable 
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>
                
            </Container>
        </>
    );
};

export default RequestsList;
