import axios from "axios";
import { API_ADVISORS_URL } from "../utils/constants";

export const getAdvisors = async (size: number, page: number, sort: string = "createdAt,desc") => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(
            `${API_ADVISORS_URL}?page=${page}&size=${size}&sort=${sort}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const createAdvisor = async (payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(API_ADVISORS_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const deleteAdvisor = async (id: string | undefined) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.delete(
            `${API_ADVISORS_URL}/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};
