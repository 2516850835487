import React, { useState } from "react";
import {
    Button,
    Container,
    Divider,
    Form,
    FormField,
    FormGroup,
    Grid,
    GridColumn,
    Header,
    Input,
    TextArea,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { createProduct } from "../../services/products.service";

const ProductsCreate = () => {
    const [productData, setProductData] = useState({
        sku: "",
        name: "",
        description: "",
        productionTimeMin: "",
        productionTimeMax: "",
        reminderTime: "",
    });

    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e: any, { name, value }: any) => {
        setProductData({
            ...productData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await createProduct(productData);

            if (response?.status === 201) {
                window.location.href = "/products";
            } else {
                throw new Error("Recurso no creado exitosamente");
            }
        } catch (error: any) {
            setError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Navbar />
            <Container style={{ marginTop: "7em" }}>
                <Form 
                    onSubmit={handleSubmit}
                    onReset={() => window.location.href = "/products"} >
                    <Grid>
                        <GridColumn floated="left" width={5}>
                            <Header as="h1">Nuevo producto</Header>
                        </GridColumn>
                        <GridColumn floated="right" width={5}>
                            <Button floated="right" positive type="submit" disabled={isSubmitting}>
                                Guardar
                            </Button>
                            <Button floated="right" type="reset">Cancelar</Button>
                        </GridColumn>
                    </Grid>

                    <Divider />

                    {error && <p>{error}</p>}

                    <FormGroup>
                        <FormField
                            width={4}
                            control={Input}
                            label="Codigo"
                            placeholder="SKU"
                            name="sku"
                            value={productData.sku}
                            onChange={handleChange}
                            required
                        />
                        <FormField
                            width={12}
                            control={Input}
                            label="Nombre"
                            placeholder="Nombre descriptivo del producto"
                            name="name"
                            value={productData.name}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup widths={"equal"}>
                        <FormField
                            control={Input}
                            type="number"
                            label="Tiempo minimo de produccion"
                            placeholder="Tiempo minimo de produccion en dias. ej. 7"
                            name="productionTimeMin"
                            value={productData.productionTimeMin}
                            onChange={handleChange}
                            required
                        />
                        <FormField
                            control={Input}
                            type="number"
                            label="Tiempo maximo de produccion"
                            placeholder="Tiempo maximo de produccion en dias. ej. 7"
                            name="productionTimeMax"
                            value={productData.productionTimeMax}
                            onChange={handleChange}
                            required
                        />
                        <FormField
                            control={Input}
                            type="number"
                            label="Dias para recodatorio"
                            placeholder="Dias para lanzar recodatorio anticipado. ej. 7"
                            name="reminderTime"
                            value={productData.reminderTime}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>

                    <FormField
                        control={TextArea}
                        label="Descripcion"
                        placeholder="Breve descripcion del producto..."
                        name="description"
                        value={productData.description}
                        onChange={handleChange}
                    />
                </Form>
            </Container>
        </>
    );
};

export default ProductsCreate;
