import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionContent,
    AccordionTitle,
    Button,
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    Container,
    Dimmer,
    Grid,
    GridColumn,
    GridRow,
    Header,
    HeaderSubheader,
    Icon,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import Navbar from "../../components/Navbar";
import OrderStatusSteps from "../../components/OrderStatusSteps";
import { useParams } from "react-router-dom";
import {
    cancelOrderById,
    getOrderById,
    updatePaymentProof,
    updateRequestStatus,
} from "../../services/requests.service";
import {
    OPTIONS_NUEVO_LEON_CITIES,
    ORDER_STATUS_CANCELADO,
    ORDER_STATUS_LISTO_PARA_AGENDAR,
    ORDER_STATUS_PROYECTO_TERMINADO,
} from "../../utils/constants";
import Order from "../../types/entities/Order";
import { setHours, setMinutes } from "date-fns";
import './styles.css';
import { formatDateTime } from "../../utils/datetimeUtils";
import { formatMoney } from "../../utils/numberUtils";

const OrdersDetail = () => {
    const [order, setOrder] = useState<Order>();
    const [isOrderFetched, setIsOrderFetched] = useState(false);
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [requestStatus, setRequestStatus] = useState("");
    const [installationDate, setInstallationDate] = useState<Date | null>(new Date());

    // get the order id from params
    let { orderId } = useParams();

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await getOrderById(orderId);

                if (response?.status === 200) {
                    const orderResponse: Order = response?.data;
                    setOrder(orderResponse);
                    setRequestStatus(orderResponse.status);
                    setIsOrderFetched(true);
                } else {
                    throw new Error("Something fails.");
                }
            } catch (error) {
                setError("Error: " + error);
                console.error(
                    "Error al obtener el detalle de la orden:",
                    error
                );
            }
        };
        fetchOrder();
    }, [orderId]);

    const getFormatedDate = () => {
        if (!installationDate) {
            return ""; // or some default value
        }
        const yyyy = installationDate.getFullYear();
        const mm = installationDate.getMonth() + 1;
        const dd = installationDate.getDate();

        const ds = dd < 10 ? "0" + dd : dd;
        const ms = mm < 10 ? "0" + mm : mm;

        return `${yyyy}-${ms}-${ds}`;
    };

    const getFormatedTime = () => {
        if (!installationDate) {
            return ""; // or some default value
        }
        const HH = installationDate.getHours();
        const MM = installationDate.getMinutes();

        const HS = HH < 10 ? "0" + HH : HH;
        const MS = MM < 10 ? "0" + MM : MM;

        return `${HS}:${MS}`;
    };

    const onClickSaveStatus = async () => {
        setIsSubmitting(true);
        try {
            let response;
            if (requestStatus === ORDER_STATUS_LISTO_PARA_AGENDAR) {
                response = await updateRequestStatus(
                    orderId,
                    requestStatus,
                    getFormatedDate(),
                    getFormatedTime()
                );
            } else {
                response = await updateRequestStatus(orderId, requestStatus);
            }

            if (response?.status === 200) {
                setOrder(response?.data);
                setRequestStatus(response?.data.status);
                setOpen(false);
            } else {
                throw new Error("Recurso no actualizado exitosamente");
            }
        } catch (error: any) {
            console.error(error);
            setError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const onClickCancelOrder = async () => {
        setIsSubmitting(true);
        try {
            const response = await cancelOrderById(orderId);

            if (response?.status === 200) {
                setOrder(response?.data);
                setRequestStatus(response?.data.status);
                setOpenCancel(false);
            } else {
                throw new Error("Recurso no actualizado exitosamente");
            }
        } catch (error: any) {
            console.error(error);
            setError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const handlePaymentProof = async (event: any) => {
        event.preventDefault();
        try {
            const file = event.target.files[0];
            console.log(file)

            const formData = new FormData();
            formData.append( "file", file, file.name );

            const response = await updatePaymentProof(orderId, formData);

            if (response?.status === 200) {
                setOrder(response?.data);
            } else {
                throw new Error("El comprobante de pago no se ha cargado con exito, favor de contactar al area de TI");
            }
        } catch (error: any) {
            setError(error.message);
        }
    };

    return (
        <>
            <Dimmer active={!isOrderFetched} inverted>
                <Loader size="large">Cargando</Loader>
            </Dimmer>

            <Navbar />
            <Container style={{ marginTop: "7em" }}>
                <Grid>
                    <GridRow>
                        <GridColumn>
                        <Header as="h1" floated="left">
                            Detalle del pedido
                            <HeaderSubheader>
                                Folio #{order?.invoice}
                            </HeaderSubheader>
                        </Header>

                        { !order?.paymentProof && (
                            <div style={{float: "right"}} >
                                <label htmlFor="uploadFile" className="ui secondary button">
                                    Subir comprobante de pago
                                </label>
                                <input id="uploadFile" style={{display: "none"}}
                                    type="file"
                                    onChange={event => handlePaymentProof(event)}
                                />
                            </div>
                        )}

                        { order?.paymentProof &&
                            requestStatus !== ORDER_STATUS_CANCELADO &&
                            requestStatus !== ORDER_STATUS_PROYECTO_TERMINADO && (
                                <>
                                    <Modal
                                        closeOnDimmerClick
                                        closeIcon={true}
                                        size="mini"
                                        dimmer="blurring"
                                        onClose={() => setOpen(false)}
                                        onOpen={() => setOpen(true)}
                                        open={open}
                                        trigger={
                                            <Button
                                                floated="right"
                                                color="green"
                                                onClick={ () => setOpen(true) }
                                                disabled={isSubmitting}
                                            >
                                                Actualizar estatus
                                            </Button>
                                        }
                                    >
                                        <Header
                                            icon="warning circle"
                                            content="Favor de validar acción"
                                        />
                                        <ModalContent>
                                            <p>
                                                Recuerda que una vez que
                                                modifiques el estatus ya no se
                                                podra regresar al estatus
                                                anterior y se enviaran las
                                                notificaciones correspondientes
                                                al cliente.
                                            </p>
                                            {requestStatus ===
                                                ORDER_STATUS_LISTO_PARA_AGENDAR && (
                                                <DatePicker
                                                    showTimeSelect
                                                    isClearable
                                                    showIcon
                                                    minDate={new Date()}
                                                    minTime={setHours(setMinutes(new Date(),59),7)}
                                                    maxTime={setHours(setMinutes(new Date(),59),21)}
                                                    dateFormat="d-MM-yyyy h:mm aa"
                                                    selected={installationDate}
                                                    placeholderText="Fecha de instalación"
                                                    onChange={(date: Date | null) => setInstallationDate(date)}
                                                    wrapperClassName="date-picker-custom-parent"
                                                    className="date-picker-custom"
                                                />
                                            )}
                                        </ModalContent>
                                        <ModalActions>
                                            <Button
                                                color="green"
                                                onClick={onClickSaveStatus}
                                                disabled={isSubmitting}
                                            >
                                                <Icon name="checkmark" />{" "}
                                                Aceptar
                                            </Button>
                                        </ModalActions>
                                    </Modal>

                                    <Modal
                                        closeOnDimmerClick
                                        closeIcon={true}
                                        size="mini"
                                        dimmer="blurring"
                                        onClose={() => setOpenCancel(false)}
                                        onOpen={() => setOpenCancel(true)}
                                        open={openCancel}
                                        trigger={
                                            <Button
                                                floated="right"
                                                color="red"
                                                onClick={() =>setOpenCancel(true)}
                                                disabled={isSubmitting}
                                            >
                                                Cancelar pedido
                                            </Button>
                                        }
                                    >
                                        <Header
                                            icon="warning circle"
                                            content="Favor de validar acción"
                                        />
                                        <ModalContent>
                                            <p>
                                                Estas seguro que deseas cancelar
                                                este pedido?, una vez realizada
                                                esta accion ya no se podra
                                                volver a trabajar con este
                                                pedido.
                                            </p>
                                        </ModalContent>
                                        <ModalActions>
                                            <Button
                                                color="red"
                                                onClick={onClickCancelOrder}
                                                disabled={isSubmitting}
                                            >
                                                <Icon name="checkmark" />{" "}
                                                Cancelar de todos modos
                                            </Button>
                                        </ModalActions>
                                    </Modal>
                                </>
                            )}
                            </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn>
                            <OrderStatusSteps currentStep={order?.status} />
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn>
                            <Accordion fluid styled style={{ marginBottom: "1.2em" }}>
                                <AccordionTitle 
                                    active={activeIndex === 0}
                                    index={0}
                                    onClick={handleClick}>
                                    <Icon name="dropdown" /> Seguimiento del pedido
                                </AccordionTitle>
                                <AccordionContent active={activeIndex === 0}>
                                    <Table striped>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHeaderCell>Descripción</TableHeaderCell>
                                                <TableHeaderCell>Fecha</TableHeaderCell>
                                            </TableRow>
                                        </TableHeader>

                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Fecha en que se realizo el pedido</TableCell>
                                                <TableCell>{ formatDateTime(order?.orderDate) }</TableCell>
                                            </TableRow>
                                            { order?.productionStartDate && (<TableRow>
                                                <TableCell>Fecha de inicio de producción</TableCell>
                                                <TableCell>{ formatDateTime(order?.productionStartDate) }</TableCell>
                                            </TableRow>) }
                                            { order?.productionEndDate && (<TableRow>
                                                <TableCell>Fecha de fin de producción</TableCell>
                                                <TableCell>{ formatDateTime(order?.productionEndDate) }</TableCell>
                                            </TableRow>) }
                                            { order?.installationDate && (<TableRow positive>
                                                <TableCell>Fecha de instalación agendada</TableCell>
                                                <TableCell>{ formatDateTime(new Date(order?.installationDate + " " + order?.installationTime)) } </TableCell>
                                            </TableRow>) }
                                        </TableBody>
                                    </Table>
                                </AccordionContent>
                            </Accordion>
                        </GridColumn>
                    </GridRow>
                </Grid>

                {error && <p>{error}</p>}

                <Card.Group itemsPerRow={3}>
                    <Card>
                        <CardContent>
                            <CardHeader>Cliente</CardHeader>
                            <CardDescription>
                                <p>
                                    <strong>Nombre:</strong>{" "}
                                    {`${order?.name} ${order?.lastName}`}
                                </p>
                                <p>
                                    <strong>Telefono (WhatsApp):</strong>{" "}
                                    {order?.phone}
                                </p>
                                <p>
                                    <strong>Email:</strong> {order?.email}
                                </p>
                            </CardDescription>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <CardHeader>Asesor</CardHeader>
                            <CardDescription>
                                <p>
                                    <strong>Nombre:</strong>{" "}
                                    {`${order?.advisor.name} ${order?.advisor.lastName}`}
                                </p>
                                <p>
                                    <strong>Telefono:</strong>{" "}
                                    {order?.advisor.phone}
                                </p>
                                <p>
                                    <strong>Email:</strong>{" "}
                                    {order?.advisor.email}
                                </p>
                            </CardDescription>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <CardHeader>Producto</CardHeader>
                            <CardDescription>
                                <p>
                                    <strong>Codigo:</strong>{" "}
                                    {order?.product.sku}
                                </p>
                                <p>
                                    <strong>Nombre:</strong>{" "}
                                    {order?.product.name}
                                </p>
                                <p>
                                    <strong>Tiempo de produccion:</strong>{" "}
                                    {`${order?.product.productionTimeMin} - ${order?.product.productionTimeMax} dias habiles`}
                                </p>
                            </CardDescription>
                        </CardContent>
                    </Card>
                </Card.Group>

                <Card.Group itemsPerRow={2}>
                    <Card>
                        <CardContent>
                            <CardHeader>
                                Información general del pedido
                            </CardHeader>
                            <CardDescription>
                                <p>
                                    <strong>Fecha de compra:</strong>{" "}
                                    { formatDateTime(order?.orderDate) }
                                </p>
                                <p>
                                    <strong>Saldo por liquidar:</strong> $
                                    { formatMoney(order?.moneyRemaining) }
                                </p>
                                <p>
                                    <strong>Comprobante de pago: </strong>
                                    {order?.paymentProof ? (
                                        <a href={order?.paymentProof} target="_blank" rel="noreferrer">Abrir comprobante</a>
                                    ): (
                                        "No ha sido cargado aun"
                                    )}
                                    
                                </p>
                            </CardDescription>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <CardHeader>Dirección del pedido</CardHeader>
                            <CardDescription>
                                <p>
                                    <strong>Dirección:</strong> {order?.address}
                                </p>
                                <p>
                                    <strong>Codigo postal:</strong>{" "}
                                    {order?.postalCode}
                                </p>
                                <p>
                                    <strong>Ciudad:</strong>{" "}
                                    {OPTIONS_NUEVO_LEON_CITIES.find(
                                        (element) => element.key === order?.city
                                    )?.text ?? order?.city}
                                </p>
                            </CardDescription>
                        </CardContent>
                    </Card>
                </Card.Group>

                {order?.installationDate && (<Card fluid color="red" style={{ marginTop: "25px" }}>
                    <CardContent>
                        <CardHeader>
                            Información de la instalación
                        </CardHeader>
                        <CardDescription>
                            <p>
                                <strong>Fecha de instalación:</strong>{" "}
                                { formatDateTime(new Date(order?.installationDate + " " + order?.installationTime)) }
                            </p>
                        </CardDescription>
                    </CardContent>
                </Card>)}

            </Container>
        </>
    );
};

export default OrdersDetail;
