import React, { SyntheticEvent, useEffect, useState } from "react";
import {
    Button,
    Card,
    Container,
    Grid,
    GridColumn,
    Header,
    Icon,
    Message,
    Modal,
    ModalActions,
    ModalContent,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { deleteProduct, getProducts } from "../../services/products.service";
import Product from "../../types/entities/Product";
import LuxonDataTable from "../../components/LuxonDataTable";

const ProductsList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");
    const [ openDelete, setOpenDelete ] = useState(false);
    const [ productToDelete, setProductToDelete ] = useState<undefined | string>(undefined);

	const columns = [
        {
            name: "Codigo (SKU)", 
            selector: (row: Product) => row.sku,
        },
        {
            name: "Nombre",
            selector: (row: Product) => row.name,
        },
        {
            name: "Tiempo de produccion",
            selector: (row: Product) => `${row.productionTimeMin} - ${row.productionTimeMax} dias`,
        },
		{
            name: "Dia de recordatorio",
            selector: (row: Product) => row.reminderTime,
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: Product) => (
                <>
                    <Button disabled color="yellow" icon size="mini" href="/advisors/create">
                        <Icon name="pencil" />
                    </Button>
                    <Button color="red" icon size="mini" onClick={ (event) => handleOpenDelete(event, row.id) } content={row.id}>
                        <Icon name="trash" />
                    </Button>
                </>
            ),
        },
    ];

	const fetchData = async (page: number) => {
        setLoading(true);
        try {
            const response = await getProducts(perPage, page-1);
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
            const response = await getProducts(newPerPage, page-1);
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1);
        // eslint-disable-next-line
    }, []);

    const handleOpenDelete = (event: SyntheticEvent, advisorId: string) => {
        event.preventDefault();
        setOpenDelete(true);
        setProductToDelete(advisorId);
    };

    const handleCancelDelete = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenDelete(false);
        setProductToDelete(undefined);
    };

    const handleDelete = async (event: SyntheticEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await deleteProduct(productToDelete);
            if (response?.status === 204) {
                fetchData(1)
                setSuccess("El producto ha sido eliminado exitosamente.");
            } else {
                throw new Error("Error al eliminar producto.");
            }
        } catch (error: any) {
            setError(error.message);
            console.error("Error al obtener la informacion:", error);
        } finally {
            setOpenDelete(false);
            setProductToDelete(undefined);
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <Container className="container-custom">
                <Grid>
                    <GridColumn floated="left" width={5}>
                        <Header as="h1">Productos</Header>
                    </GridColumn>
                    <GridColumn floated="right" width={5}>
                        <Button href="/products/create" floated="right" primary>
                            Nuevo producto
                        </Button>
                    </GridColumn>
                </Grid>

                {error && (
                    <Message
                        error
                        onDismiss={ () => setError("") }
                        header="Se ha producido un error."
                        content={error}
                    />
                )}

                {success && (
                    <Message
                        success
                        onDismiss={ () => setSuccess("") }
                        header="Operacion realizada con exito."
                        content={success}
                    />
                )}

				<Card fluid>

                    <LuxonDataTable 
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>

            </Container>

            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="mini"
                dimmer="blurring"
                onClose={(event) => handleCancelDelete(event)}
                open={openDelete} >
                <Header
                    icon="warning circle"
                    content="Favor de validar acción"
                />
                <ModalContent>
                    <p>
                        Una vez eliminado este producto, sus datos no podran ser recuperados. Si estas deacuerdo da click en Eliminar.
                    </p>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelDelete(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => handleDelete(event) }>
                        <Icon name="checkmark" /> Eliminar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default ProductsList;
